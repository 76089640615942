<template>
  <div class="worksManage" flex column>
    <div v-if="!show_set">
      <div class="btn_top">
        <el-button type="primary" @click="go_new()">新建作品</el-button>
      </div>
      <NoneData v-if="works_list.length === 0"></NoneData>
      <div class="my_works" v-if="works_list.length">
        <div class="work_item" v-for="(item, index) in works_list" :key="index">
          <!-- <div class="rank_one"> -->
          <img
            v-if="
              item.cover_image &&
              item.cover_image.match(
                /[.png]|[.jpg]|[.jpeg]|[.PNG]|[.JPG]|[.JPEG]$/
              )
            "
            :src="item.cover_image"
            alt=""
            width="108"
            height="152"
            class="cover_img"
            @click="book_info(item.id, item.signed_type)"
          />
          <img
            v-else
            src="../../../assets/images/default.png"
            alt=""
            width="108"
            class="cover_img"
            @click="book_info(item.id, item.signed_type)"
            height="152"
          />
          <!-- </div> -->
          <div class="books_detail">
            <div class="books_detail_top">
              <span
                @click="book_info(item.id, item.signed_type)"
                style="font-size: 16px"
                >{{ item.name }}</span
              >
              <el-tag type="warning" v-if="item.signed_type === 1"
                >待签约</el-tag
              >
              <el-tag type="warning" v-if="item.signed_type === 2"
                >已签约</el-tag
              >
            </div>
            <div class="books_like" style="color: #666">
              <span>{{ item.total_far }} 收藏</span>
              <span>{{ item.total_like }} 点赞</span>
              <span
                >{{
                  item.click > 99999
                    ? (item.click / 10000).toFixed(2) + "w"
                    : item.click
                }}
                人气</span
              >
            </div>
            <div class="works_content" flex align>
              <p hidden style="color: #666">{{ item.intro }}</p>
            </div>
            <div class="works_btm">
              <span style="font-size: 12px; color: #999"
                >字数{{ item.total_words }}万</span
              >
              <div class="btn_arr">
                <el-button type="primary" plain @click="set_book(item)"
                  >作品设置</el-button
                >
                <el-button type="primary" @click="go_write(item)"
                  >去写作</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="!show_set ? 'unshow_set' : 'show_set'" v-if="cur_work">
      <p class="show_set_title">
        <span class="iconfont icon-back close_set" @click="close_set"></span>
        <span>{{ cur_work.name }}</span>
      </p>
      <div class="change_box">
        <div class="change_box_con">
          <div>
            <label for="work_name" class="set_label">作品名称</label>
            <input
              type="text"
              id="work_name"
              key-input
              disabled
              placeholder="请输入"
              style="cursor: not-allowed"
              v-model="work_name"
            />
            <p class="limit_title">
              <span>修改书名请联系管理员~</span>
            </p>
          </div>
          <div class="area_inro">
            <label for="work_introa" class="set_label">作品简介</label>
            <div flex align-end>
              <textarea
                name=""
                id="work_introa"
                key_textarea
                v-model="work_intro"
                aria-placeholder="请输入"
                maxlength="220"
              ></textarea>
              <p class="limit_title">
                <span>{{ work_intro ? work_intro.length : 0 }}/220字</span>
                <span>不超过220个字哦~</span>
              </p>
            </div>
          </div>
          <div>
            <label for="" class="set_label">作品封面</label>
            <div class="change_img">
              <upPic
                v-model="cover_image"
                :up_type="'system'"
                :coverImg="cur_work.cover_image"
                class="change_box_con_file"
                @input="cover_change($event)"
                ref="refCover"
              ></upPic>
            </div>
            <div class="tips" flex>
              <p>30天内只能上传一次哦~</p>
            </div>
          </div>
        </div>
        <div class="change_box_bottom">
          <div>
            <label for="" class="set_label">签约方式</label>
            <p
              v-text="
                cur_work.signed_norm === 1
                  ? '按章节分成'
                  : cur_work.signed_norm === 2
                  ? '整本买断'
                  : '千字买断'
              "
            ></p>
          </div>
          <div flex>
            <label for="" class="set_label">作品标签</label>
            <el-tag
              type="success"
              v-for="(item, index) of cur_work.copy_arr"
              :key="index"
              >{{ item }}</el-tag
            >
          </div>
          <div>
            <label for="" class="set_label">作品状态</label>
            <p v-text="cur_work.status === 1 ? '完结' : '连载中'"></p>
          </div>
          <div class="change_box_button" center>
            <button type="button" key-button @click="up_change()">保存</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="isShowDialog"
      width="30%"
      :before-close="handleClose"
    >
      <span>30天内您已经上传过一次封面了，不能再修改封面了哦~</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { AuthorCenter, AuthorcUp } from "@/https/mine_center.js";
import upPic from "@/components/input/up_pic2";
import { upload_img } from "@/https/api.js";
export default {
  name: "worksManage",
  components: { upPic },
  props: {},
  data() {
    return {
      works_list: [],
      book_name: "",
      book_id: "",
      show_set: false,
      input_name: "",
      work_name: "",
      work_intro: "",
      cur_work: {},
      cover_image: "",
      labels: [],
      isShowDialog: false,
    };
  },
  methods: {
    // 书籍详情
    go_detail(row) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: row.id,
        },
      });
      window.open(href, "_blank");
    },
    // 新建书籍
    go_new() {
      this.$router.push({
        path: "/new_works",
      });
    },
    // 去写作
    go_write(row) {
      const { href } = this.$router.resolve({
        path: "/write_center",
        query: {
          books_id: row.id,
        },
      });
      window.open(href, "_blank");
    },
    // 打开书籍设置
    set_book(row) {
      this.show_set = !this.show_set;
      this.cur_work = row;
      let arr = [];
      if (this.cur_work.labels) {
        arr = this.cur_work.labels.name;
      } else {
        arr = [];
      }
      this.cur_work.copy_arr = arr;
      this.work_name = row.name;
      this.work_intro = row.intro;
      // let params = {
      //   id: row.id,
      // };
      // AuthorCenter(params).then((res) => {
      //   console.log(res.data);
      //   this.labels = res.data;
      // });
    },
    // 书籍详情
    book_info(id, signed_type) {
      if (signed_type) {
        const { href } = this.$router.resolve({
          path: "/show_book",
          query: {
            id: id,
          },
        });
        window.open(href, "_blank");
      }
    },
    // 关闭书籍设置
    close_set() {
      this.show_set = !this.show_set;
      this.$refs.refCover.url = "";
      // 如果发生了更改，重新获取
      this.get_works_list();
      this.get_works_list();
    },
    // submit_change(id) {
    //   console.log(id);
    //   // this.row.pre_time
    //   // 限制30天修改一次封面方法一：计算时间差，超过30天不能提交Img或者将img设置为空并提示作者
    //   let day = this.time();
    //   if (day > 30) {
    //     this.cover_image = "";
    //   }
    //   console.log(this.cover_image);
    //   let params = {
    //     book_id: id,
    //     name: this.work_name,
    //     intro: this.work_intro,
    //     cover_image: this.cover_image,
    //   };
    // },
    // 计算时间差
    time() {
      let date1 = new Date(this.cur_work.last_update_time);
      let date2 = new Date();
      let dif_time = Math.abs(date2.getTime() - date1.getTime());
      let day = Math.floor(dif_time / (24 * 3600 * 1000));
      return day;
    },
    // 获取作品列表
    get_works_list() {
      AuthorCenter().then((res) => {
        this.works_list = res.data;
      });
    },
    // 30天内上传一次上传图片
    cover_change(data) {
      let day = this.time();
      if (day < 30) {
        // this.$confirm(
        //   "30天内您已经上传过一次封面了，不能再修改封面了哦~",
        //   "提示信息",
        //   {
        //     confirmButtonText: "确定",
        //     type: "warning",
        //   }
        // )
        //   .then(() => {
        //     this.$refs.refCover.url = "";
        //   })
        //   .catch(() => {
        //     this.$refs.refCover.url = "";
        //   });
        this.isShowDialog = true;
      } else {
        this.cover_image = data;
      }
    },

    handleClose(done) {
      this.$refs.refCover.url = "";
      done();
    },
    // 作品设置上传修改
    up_change() {
      if (!this.work_name) {
        this.$alert(`${this.work_name}不能为空`, "重要提示", {
          confirmButtonText: "确定",
        });
        return;
      } else if (!this.work_intro) {
        this.$alert(`${this.work_intro}不能为空`, "重要提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      let params = {
        table: 1,
        bookid: this.cur_work.id,
        name: this.work_name || this.cur_work.name || "",
        intro: this.work_intro || this.cur_work.intro || "",
        cover_image: this.cover_image || this.cur_work.cover_image || "",
        // labels: [],
      };
      AuthorcUp(params).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: "修改成功!",
            type: "success",
          });
          this.close_set();
          this.get_works_list();
          this.$refs.refCover.url = "";
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
  },
  created() {
    this.get_works_list();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../../utils/tool.scss";

// @include flex-center();
.worksManage {
  // position: relative;
}
.btn_top {
  width: 100%;
  padding: 10px 0;
  text-align: end;
  /deep/.el-button--primary {
    padding: 8px 15px !important;
  }
}
.hidden_work_title {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #999;
}
.my_works {
  padding: 15px;
  border-top: 1px solid #eeeeee;
}
.work_item {
  @include flex-row();
  padding: 5px 0;
  border-bottom: 1px solid #eeeeee;
  height: 180px;
  .cover_img {
    width: 108px;
    height: 152px;
    margin-right: 15px;
    cursor: pointer;
  }
  .books_detail {
    width: 100%;
    height: 152px;
    font-size: 14px;
    @include flex-column();
    .books_detail_top {
      padding-bottom: 15px;
      > span {
        margin-right: 15px;
        cursor: pointer;
      }
      > span:hover {
        color: #409eff;
      }
      /deep/.el-tag {
        line-height: 28px !important;
        height: 28px !important;
      }
    }
    .books_like {
      line-height: 16px;
      width: 300px;
      display: flex;
      padding: 5px 0;
      span:nth-child(2) {
        text-align: center;
        padding: 0 20px;
        margin: 0 10px;
        border-left: 1px solid #666666;
        border-right: 1px solid #666666;
      }
    }
    .works_content {
      height: 60px;
      padding: 5px 10px 10px 5px;
      > p {
        max-width: 550px;
        text-indent: 2em;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 12px;
      }
    }
    .works_btm {
      @include flex-row();
      justify-content: space-between;
      margin-top: auto;
    }
    /deep/.el-button {
      padding: 8px 15px !important;
    }
  }
}
.unshow_set {
  display: none;
}
.show_set {
  // position: absolute;
  width: 100%;
  height: 100%;
  // left: 0;
  // top: 0;
  background-color: #fff;
  .show_set_title {
    line-height: 30px;
    .close_set {
      cursor: pointer;
      font-size: 18px;
      margin-right: 10px;
    }
    .close_set:hover {
      color: #409eff;
    }
  }
  .change_box {
    padding: 20px 60px;
    .set_label {
      font-size: 12px;
      color: #999;
      margin-right: 10px;
      width: 50px !important;
    }
    .tips {
      color: #e54a4a;
      font-size: 15px;
      height: 174px;
      align-items: flex-end;
      padding: 0 0 15px 20px;
    }
    .change_box_con {
      border-bottom: 1px solid;
      border-image: linear-gradient(
          to right,
          rgba(228, 228, 228, 0.2) 0%,
          rgba(209, 205, 205, 1) 50%,
          rgba(228, 228, 228, 0.2) 100%
        )
        2 2 2 2;
      > div {
        display: flex;
        align-items: center;
        margin: 10px;
      }
      .area_inro {
        width: calc(100% - 50px);
        height: 160px;
        > div {
          width: 620px;
          height: 100px;
          // margin-left: 10px;
        }
      }
      .limit_title {
        color: #999;
        font-size: 12px;
        margin-left: 20px;
        > span {
          padding-right: 10px;
        }
      }

      #work_name {
        height: 40px;
        width: 240px;
        border: 1px solid rgb(228, 228, 228);
        padding-left: 5px;
        border-radius: 5px;
      }
      #work_introa {
        border: 1px solid rgb(228, 228, 228);
        width: calc(100% - 200px);
        height: 100%;
        padding: 10px;
        border-radius: 5px;
        line-height: 30px;
      }
      #work_introa::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f2f2f2;
      }
      #work_introa::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        background-color: #b5b1b1;
        cursor: pointer;
      }
      #work_introa::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #b5b1b1;
      }

      .change_img {
        position: relative;
        box-shadow: 2px 2px 6px #bbbbbb;
        width: 135px;
        height: 190px;
        border-radius: 2px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 2px 2px 8px rgb(228, 228, 228);
          cursor: pointer;
        }
      }
    }
    .change_box_bottom {
      width: 100%;
      > div {
        display: flex;
        align-items: center;
        margin: 10px;
      }
      .el-tag {
        margin: 5px;
      }
      .change_box_button {
        width: calc(100% - 20px);
        height: 50%;
        margin-top: 50px;
        button {
          background-color: #409eff;
          cursor: pointer;
          color: #fff;
        }
        button:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.nothing_pic {
  width: 100%;
}
.book_name {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
</style>
